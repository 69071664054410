import React from 'react'

// App content
import '../styles/flavor.css'
import styles from '../styles/flavor-styles'

const Flavor = ({ category }) => {
  return (
    <div
      id="flavor-main-div"
      data-testid="flavor-main-div"
      css={[styles.flavorMainDiv]}
    >
      <h3
        id={`flavor-category-${category.flavorCategory}`}
        data-testid={`flavor-category-${category.flavorCategory}`}
        css={[styles.flavorCategoryH3]}
      >
        {category.flavorCategory}
      </h3>
      <ul
        id="flavor-category-list"
        data-testid="flavor-category-list"
        css={[styles.flavorCategoryList]}
      >
        {category.flavors.map((flavor) => (
          <li
            key={flavor.flavorId}
            id={`flavor-category-list-${flavor.flavorId}`}
            data-testid={`flavor-category-list-${flavor.flavorId}`}
            css={[styles.flavorCategoryListItem]}
          >
            <h4
              id={`flavor-category-list-${flavor.flavorName}`}
              data-testid={`flavor-category-list-${flavor.flavorName}`}
              css={[styles.flavorCategoryListItemH4]}
            >
              {flavor.flavorName}
            </h4>
            <div
              id="flavor-category-list-item-pretext"
              data-testid="flavor-category-list-item-pretext"
              css={[styles.flavorCategoryListItemPretext]}
            >
              {flavor.flavorPreText}
            </div>{' '}
            <div
              id="flavor-category-list-item-text"
              data-testid="flavor-category-list-item-text"
              css={[styles.flavorCategoryListItemText]}
            >
              {flavor.flavorText}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Flavor
