import { theme } from '../utils/typography'
import { colors, presets } from '../utils/theme'

const styles = {
  donutPage: {
    fontFamily: theme.donutFontFamily,
    minWidth: '100%',
  },

  donutSection: {
    width: '100%',
    marginTop: 0,
  },

  donutHeaderSection: {
    width: '100%',
    backgroundImage:
      "url('https://res.cloudinary.com/collins-ortho/image/upload/v1657287004/the_donut/HeaderBackground.jpg')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    minHeight: '2000px',
    position: 'relative',

    [presets.Mobile]: {
      minHeight: '1800px',
    },

    [presets.Desktop]: {
      minHeight: '1600px',
    },
  },
  donutPageNumber: {
    position: 'absolute',

    backgroundColor: colors.donutTextBlue,
    padding: '10px',
    right: 0,
    width: '250px',
    minWidth: '250px',
    [presets.Large]: {},
  },
  overlayBusinessHours: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  dialog: {
    backgroundColor: 'none',
  },
  donutPageBusinessHours: {
    position: 'absolute',

    backgroundColor: colors.donutBackgroundDark,
    padding: '10px',
    right: 0,
    top: '50px',
    width: '250px',
    minWidth: '250px',
    [presets.Large]: {},
  },
  donutPageNumberLnk: {
    textDecoration: 'none !important',
    color: '#fff !important',
  },
  donutPageBusinessHoursLink: {
    textDecoration: 'none !important',
    color: '#fff !important',
    fontWeight: 'bold',
  },
  theDonutLogo: {
    marginTop: '100px',
    width: '200px',

    [presets.Phablet]: {
      marginTop: '30px',
      width: '350px',
    },
  },
  tdpHeaderMain: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    zIndex: '1',
  },
  tdpHeaderMainContent: {
    color: colors.donutText,
    backgroundColor: colors.donutBackgroundLight,
    border: '10px solid #F2F2F2',
    paddingBottom: '36pt',
    minHeight: '500px',
    width: '90%',
    position: 'relative',
    zIndex: '2',

    [presets.Tablet]: {
      minHeight: '500px',
      width: '75%',
    },
  },
  tdpHeaderMainH2: {
    color: colors.donutTextBlue,
    fontFamily: theme.donutHeaderFontFamily,
    fontSize: '20pt',

    marginTop: '18pt',
    paddingTop: '18pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
    zIndex: '2',

    [presets.Phablet]: {
      fontSize: '36pt',
    },
  },
  tdpMainVideo: {
    width: '300px',
    height: '200px',

    [presets.Tablet]: {
      width: '560px',
      height: '315px',
    },
  },
  tdpHeaderPContainer: {
    padding: '0 18pt 0 18pt',
    zIndex: '2',

    [presets.Phablet]: {
      padding: '0 36pt 0 36pt',
    },
  },
  tdpContentDefaultP: {
    fontFamily: theme.donutFontFamily,
    fontSize: '1rem',
    lineHeight: '1.5',
    letterSpacing: '.9px',

    [presets.Phablet]: {
      fontSize: '1.3rem',
    },
  },
  tdpContentDefaultLink: {
    color: colors.donutTextLink,
  },
  tdpHeaderTeamImg: {
    position: 'absolute',
    maxWidth: '425px',
    top: '95%',
    zIndex: '2',

    [presets.Mobile]: {
      top: '90%',
    },

    [presets.Tablet]: {
      top: '90%',
    },
  },
  donutMenuSection: {
    width: '100%',
    marginTop: 0,
    backgroundImage:
      "url('https://res.cloudinary.com/collins-ortho/image/upload/v1656184306/the_donut/TheDonutWebsite_Pic_Background.jpg')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '300px',
  },
  tdpOurMenuDiv: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  tdpHeaderH2: {
    color: colors.donutTextLight,
    fontFamily: theme.donutHeaderFontFamily,
    fontSize: '36pt',
    marginTop: '18pt',
    paddingTop: '18pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  tdpOurMenuContentDiv: {
    padding: '0 20% 0 20%',
  },
  tdpOurMenuContent: {
    color: colors.donutTextLight,
    fontFamily: theme.donutFontFamily,
    fontSize: '1.3rem',
    lineHeight: '1.5',
    letterSpacing: '.9px',
    marginBottom: '18pt',
  },
  donutHowToOrderSection: {
    width: '100%',
    marginTop: 0,
    backgroundImage:
      "url('https://res.cloudinary.com/collins-ortho/image/upload/v1666108661/the_donut/TheDonutWebsite_Pic_Ordering.jpg')",
    backgroundSize: '350px',
    minHeight: '300px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    [presets.Tablet]: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      minHeight: '600px',
      backgroundPosition: 'center',
    },
  },
  donutFlavorBeverageSection: {
    width: '100%',
    minHeight: '1100px',
    backgroundColor: colors.donutBackgroundDark,
  },
  tdpFlavorsAndBeveragesDiv: {
    backgroundColor: colors.donutBackgroundDark,
    alignItems: 'center',
    textAlign: 'center',
  },
  tdpFlavorsAndBeveragesH2: {
    color: colors.donutTextLight,
    fontFamily: theme.donutHeaderFontFamily,
    fontSize: '36pt',
    paddingTop: '40pt',
    marginBottom: '40pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  tdpFlavorsDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    margin: '0 5% 0 5%',

    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'start',
      margin: '0 5% 0 5%',
    },
  },
  tdpBeveragesContent: {
    color: colors.donutTextLight,
    fontFamily: theme.donutFontFamily,
    fontSize: '1.3rem',
    marginBottom: '25pt',
    lineHeight: '1.5',
    letterSpacing: '.9px',
    margin: '0 5% 0 5%',
  },
  donutOurCauseSection: {
    width: '100%',
    marginTop: 0,
  },
  tdpOurCauseDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.donutBackgroundLight,
    textAlign: 'center',
  },
  tdpOurCauseH2: {
    color: colors.donutTextBlue,
    fontFamily: theme.donutHeaderFontFamily,
    fontSize: '36pt',
    paddingTop: '18pt',
    marginTop: '18pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  tdpOurCauseContentDiv: {
    padding: '0 15% 0 15%',
  },
  tdpOurCauseDonateButtonDiv: {
    marginTop: '20pt',
    marginBottom: '20pt',
  },
  tdpOurCauseDonateButton: {
    backgroundColor: colors.donutTextBlue,
    color: colors.donutTextLight,
    fontSize: '20px',
    border: 'none',
    padding: '15px 30px 15px 30px',
    marginTop: '15px',
  },
  donutWingsSection: {
    width: '100%',
    marginTop: 0,
    backgroundImage:
      "url('https://res.cloudinary.com/collins-ortho/image/upload/v1656281900/the_donut/TheDonutWebsite_Pic_Wings.jpg')",
    backgroundSize: '350px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '200px',

    [presets.Tablet]: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      minHeight: '400px',
      backgroundPosition: 'center',
    },
  },
  donutBehindOurWingsSection: {
    width: '100%',
    marginTop: 0,
  },
  tdpBehindOurWingsDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.donutBackgroundLight,
    textAlign: 'center',
    zIndex: '1',
  },
  tdpBehindOurWingsH2: {
    color: colors.donutTextBlue,
    fontFamily: theme.donutHeaderFontFamily,
    fontSize: '36pt',
    paddingTop: '18pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
    zIndex: '2',
  },
  tdpBehindOurWingsContentDiv: {
    padding: '0 15% 0 15%',
    marginBottom: '18pt',
  },
  donutFooterSection: {
    width: '100%',
    marginTop: 0,
  },
  tdpFooterDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.donutBackgroundDark,
    textAlign: 'center',
  },
  tdpFooterDivHeaderH3: {
    fontSize: '25pt',
    marginTop: '18pt',
    marginBottom: '18pt',
    paddingTop: '18pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
    zIndex: '2',
    color: colors.donutTextLight,
    fontFamily: theme.donutHeaderFontFamily,
  },
  tdpFooterDivContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '50px',
    fontFamily: theme.donutFontFamily,
    minWidth: '1200px',
    [presets.Hd]: {
      flexDirection: 'row',
    },
  },

  tdpFooterDivContentLocation: {
    color: colors.donutTextBlue,
    fontSize: '20pt',
    padding: '0px 20px 0px 20px',
    maxHeight: '150px',
    minWidth: '300px',
    marginBottom: '20px',

    [presets.Tablet]: {
      borderRight: '2px solid #00ABCD',
    },
  },
  tdpFooterDivContentLocationAddress: {
    fontSize: '16pt',
    margin: '20px 0 0 0',
    marginBottom: '20px',
    color: colors.donutTextLight,
  },
  tdpFooterDivContentHours: {
    color: colors.donutTextBlue,
    fontSize: '20pt',

    padding: '0px 20px 0px 20px',
    minWidth: '300px',
    marginBottom: '20px',
    maxHeight: '150px',

    [presets.Tablet]: {
      borderRight: '2px solid #00ABCD',
    },
  },
  tdpFooterDivContentHoursTimes: {
    fontSize: '16pt',
    margin: '20px 0 0 0',
    color: colors.donutTextLight,
  },
  tdpFooterDivContentContact: {
    fontSize: '16pt',
    margin: '20px 0 0 0',
    color: colors.donutTextLight,
    textDecoration: 'none',
    fontWeight: '400',
  },
  tdpFooterDivContentContactNumber: {
    fontSize: '16pt',
    color: colors.donutTextLight,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  tdpFooterDivContentContactEmail: {
    fontSize: '16pt',
    color: colors.donutTextLight,
    textDecoration: 'none',
    fontWeight: '400',
  },
  tdpFooterDivContentGetInvolved: {
    color: colors.donutTextBlue,
    fontSize: '20pt',
    padding: '0px 20px 0px 20px',
    minWidth: '300px',
    maxHeight: '100px',
  },

  tdpFooterDivContentGetInvolvedButtonDiv: {
    fontSize: '16pt',
    margin: '30px 0 20px 0',
  },
  tdpFooterDivContentGetInvolvedButton: {
    backgroundColor: colors.donutTextBlue,
    color: colors.donutTextLight,
    fontSize: '20px',
    border: 'none',
    padding: '15px 30px 15px 30px',
    textDecoration: 'none',
  },
}

export default styles
