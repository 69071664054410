import React, { useState } from 'react'
import _ from 'lodash'

// App components
import Layout from '../../components/layout'
import Flavor from '../../components/Flavor'
import CustomLink from '../../components/CustomLink'
import HtmlSanitize from '../../components/HtmlSanitize'

// App theDonutContent
import TheDonutContent from '../../content/the-donut'
import '../../styles/the-donut.css'
import styles from '../../styles/the-donut-styles'

// App utils
import { colors } from '../../utils/theme'

/**
 * The Donut Page
 */
class TheDonut extends React.Component {
  state = {
    show: false,
  }

  toggle = () => this.setState((currentState) => ({ show: !currentState.show }))

  render() {
    // Retrieve theDonutContent for The Donut page
    const theDonutContent = TheDonutContent

    // Return list of current flavors by flavor category
    const renderFlavorColumn = theDonutContent.FLAVOR_LIST.map(
      (flavorCategory) => (
        <Flavor key={flavorCategory.flavorCatId} category={flavorCategory} />
      )
    )

    return (
      <Layout location={this.props.location}>
        <div
          id="the-donut-page"
          data-testid="the-donut-page"
          css={styles.donutPage}
        >
          {this.state.show && (
            <div className="popup" onClick={this.toggle}>
              <div className="popuptext">
                <img
                  alt="The Donut Business Hours"
                  src="https://res.cloudinary.com/collins-ortho/image/upload/v1687895833/the_donut/the_donut_calendar.png"
                  width="100%"
                  height="auto"
                ></img>
              </div>
            </div>
          )}
          {/* 
            Donut Page Section 
            Header
          */}
          <section
            id="tdp-header-section"
            data-testid="tdp-header-section"
            css={[styles.donutHeaderSection]}
          >
            <div id="the-donut-page-phone-number" css={styles.donutPageNumber}>
              <a href="tel:5075127100" css={styles.donutPageNumberLnk}>
                The Donut: (507) 512-7100
              </a>
            </div>

            <div
              id="the-donut-page-click-for-business-hours"
              onClick={this.toggle}
              css={styles.donutPageBusinessHours}
            >
              <div
                id="the-donut-page-click-for-business-hours-link"
                css={styles.donutPageBusinessHoursLink}
              >
                Click for Business Hours
              </div>
            </div>
            <div id="tdp-header-logo-div" data-testid="tdp-header-logo-div">
              <img
                id="tdp-header-logo-img"
                data-testid="tdp-header-logo-img"
                src="https://res.cloudinary.com/collins-ortho/image/upload/v1656184305/the_donut/TheDonutWebsite_Logo.png"
                alt="The Donut Logo"
                css={[styles.theDonutLogo]}
              />
            </div>

            <div
              id="tdp-header-main"
              data-testid="tdp-header-main"
              css={[styles.tdpHeaderMain]}
            >
              <div
                id="tdp-header-main-theDonutContent"
                data-testid="tdp-header-main-theDonutContent"
                css={[styles.tdpHeaderMainContent]}
              >
                <h2
                  id="tdp-header-main-h2"
                  data-testid="tdp-header-main-h2"
                  css={[styles.tdpHeaderMainH2]}
                >
                  {theDonutContent.HEADER_TITLE_1}
                  <br />
                  {theDonutContent.HEADER_TITLE_2}
                </h2>
                <iframe
                  id="tdp-main-video"
                  css={[styles.tdpMainVideo]}
                  src="https://www.youtube.com/embed/3RTW2ia-v5I"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div
                  id="tdp-header-main-p-container"
                  data-testid="tdp-header-p-container"
                  css={[styles.tdpHeaderPContainer]}
                >
                  <p
                    id="tdp-header-main-p-1"
                    data-testid="tdp-header-main-p-1"
                    css={styles.tdpContentDefaultP}
                  >
                    <HtmlSanitize
                      contentString={theDonutContent.HEADER_P_1}
                    ></HtmlSanitize>
                  </p>
                  <p
                    id="tdp-header-main-p-2"
                    data-testid="tdp-header-main-p-2"
                    css={styles.tdpContentDefaultP}
                  >
                    <HtmlSanitize
                      contentString={theDonutContent.HEADER_P_2}
                    ></HtmlSanitize>
                  </p>
                </div>
              </div>
              <img
                id="tdp-header-team-img"
                data-testid="tdp-header-team-img"
                src="https://res.cloudinary.com/collins-ortho/image/upload/v1656184310/the_donut/TheDonutWebsite_Pic_Team.png"
                alt="The donut team photo"
                width="100%"
                height="auto"
                css={[styles.tdpHeaderTeamImg]}
              />
            </div>
          </section>
          {/* 
            Donut Page Section 
            Our Menu
          */}
          <section
            id="the-donut-page-our-menu-section"
            data-testid="the-donut-page-our-menu-section"
            css={[styles.donutMenuSection]}
          >
            <div
              id="tdp-our-menu-div"
              data-testid="tdp-our-menu-div"
              css={[styles.tdpOurMenuDiv]}
            >
              <h2
                id="tdp-header-h2"
                data-testid="tdp-header-h2"
                css={[styles.tdpHeaderH2]}
              >
                {theDonutContent.HEADER_OUR_MENU}
              </h2>
              <div
                id="tdp-our-menu-theDonutContent-div"
                data-testid="tdp-our-menu-theDonutContent-div"
                css={[styles.tdpOurMenuContentDiv]}
              >
                <p
                  id="tdp-our-menu-theDonutContent"
                  data-testid="tdp-our-menu-theDonutContent"
                  css={[styles.tdpOurMenuContent]}
                >
                  <HtmlSanitize
                    contentString={theDonutContent.OUR_MENU_CONTENT}
                  ></HtmlSanitize>
                </p>
              </div>
            </div>
          </section>
          {/* 
            Donut Page Section 
            How-to Order
          */}
          <section
            id="the-donut-page-how-to-order-section"
            data-testid="the-donut-page-how-to-order-section"
            css={[styles.donutHowToOrderSection]}
          ></section>
          {/* 
            Donut Page Section 
            Flavors and Beverages
          */}
          <section
            id="tdp-flavors-and-beverages-section"
            data-testid="tdp-flavors-and-beverages-section"
            css={[styles.donutFlavorBeverageSection]}
          >
            <div
              id="tdp-flavors-and-beverages-div"
              data-testid="tdp-flavors-and-beverages-div"
              css={[styles.tdpFlavorsAndBeveragesDiv]}
            >
              <div id="tdp-flavors-div" data-testid="tdp-flavors-div">
                <h2
                  id="tdp-flavors-h2"
                  data-testid="tdp-flavors-h2"
                  css={[styles.tdpFlavorsAndBeveragesH2]}
                >
                  {theDonutContent.HEADER_FLAVORS}
                </h2>
                <div
                  id="tdp-flavors-div"
                  data-testid="tdp-flavors-div"
                  css={[styles.tdpFlavorsDiv]}
                >
                  {renderFlavorColumn}
                </div>
              </div>
              <div>
                <h2
                  id="tdp-beverages-h2"
                  data-testid="tdp-beverages-h2"
                  css={[styles.tdpFlavorsAndBeveragesH2]}
                >
                  {theDonutContent.HEADER_BEVERAGES}
                </h2>
                <p
                  id="tdp-beverages-theDonutContent"
                  data-testid="tdp-beverages-theDonutContent"
                  css={[styles.tdpBeveragesContent]}
                >
                  {theDonutContent.BEVERAGES_CONTENT}
                </p>
              </div>
            </div>
          </section>
          {/* 
            Donut Page Section 
            Our Cause
          */}
          <section
            id="the-donut-page-our-cause"
            data-testid="the-donut-page-our-cause"
            css={[styles.donutOurCauseSection]}
          >
            <div
              id="tdp-our-cause-div"
              data-testid="tdp-our-cause-div"
              css={[styles.tdpOurCauseDiv]}
            >
              <h2
                id="tdp-our-cause-h2"
                data-testid="tdp-our-cause-h2"
                css={[styles.tdpOurCauseH2]}
              >
                {theDonutContent.HEADER_OUR_CAUSE}
              </h2>
              <div
                id="tdp-our-cause-theDonutContent-div"
                data-testid="tdp-our-cause-theDonutContent-div"
                css={[styles.tdpOurCauseContentDiv]}
              >
                <div
                  id="tdp-our-cause-theDonutContent-div-1"
                  data-testid="tdp-our-cause-theDonutContent-div-1"
                  css={styles.tdpContentDefaultP}
                >
                  <HtmlSanitize
                    contentString={theDonutContent.OUR_CAUSE_1}
                  ></HtmlSanitize>
                </div>
                <br />
                <div
                  id="tdp-our-cause-theDonutContent-div-2"
                  data-testid="tdp-our-cause-theDonutContent-div-2"
                  css={styles.tdpContentDefaultP}
                >
                  <HtmlSanitize
                    contentString={theDonutContent.OUR_CAUSE_2}
                  ></HtmlSanitize>
                </div>
              </div>
              <div
                id="tdp-our-cause-donate-button-div"
                data-testid="tdp-our-cause-donate-button-div"
                css={[styles.tdpOurCauseDonateButtonDiv]}
              >
                <CustomLink to="/contact-us/adopt-a-shark">
                  <button
                    id="tdp-our-cause-donate-button"
                    data-testid="tdp-our-cause-donate-button"
                    css={[styles.tdpOurCauseDonateButton]}
                  >
                    {theDonutContent.DONATE_BUTTON_TEXT}
                  </button>
                </CustomLink>
              </div>
            </div>
          </section>
          {/* 
            Donut Page Section 
            Wings
          */}
          <section
            id="the-donut-page-wings-section"
            data-testid="the-donut-page-wings-section"
            css={[styles.donutWingsSection]}
          ></section>
          {/* 
            Donut Page Section 
            Behind Our Wings
          */}
          <section
            id="the-donut-page-behind-our-wings-section"
            data-testid="the-donut-page-behind-our-wings-section"
            css={[styles.donutBehindOurWingsSection]}
          >
            <div
              id="tdp-behind-our-wings-div"
              data-testid="tdp-behind-our-wings-div"
              css={[styles.tdpBehindOurWingsDiv]}
            >
              <h2
                id="tdp-behind-our-wings-h2"
                data-testid="tdp-behind-our-wings-h2"
                css={[styles.tdpBehindOurWingsH2]}
              >
                {theDonutContent.HEADER_BEHIND_OUR_WINGS}
              </h2>
              <div
                id="tdp-behind-our-wings-theDonutContent-div"
                data-testid="tdp-behind-our-wings-theDonutContent-div"
                css={[styles.tdpBehindOurWingsContentDiv]}
              >
                <p
                  id="tdp-behind-our-wings-theDonutContent-text"
                  data-testid="tdp-behind-our-wings-theDonutContent-text"
                  css={styles.tdpContentDefaultP}
                >
                  <HtmlSanitize
                    contentString={theDonutContent.BEHIND_OUR_WINGS_CONTENT}
                  ></HtmlSanitize>
                </p>
              </div>
            </div>
          </section>
          {/* 
            Donut Page Section 
            Footer
          */}
          <section
            id="the-donut-page-footer-section"
            data-testid="the-donut-page-footer-section"
            css={[styles.donutFooterSection]}
          >
            <div
              id="tdp-footer-div"
              data-testid="tdp-footer-div"
              css={[styles.tdpFooterDiv]}
            >
              <div
                id="tdp-footer-div-header"
                data-testid="tdp-footer-div-header"
              >
                <h3
                  id="tdp-footer-div-header-h3"
                  data-testid="tdp-footer-div-header-h3"
                  css={[styles.tdpFooterDivHeaderH3]}
                >
                  {' '}
                  Find{' '}
                  <span
                    css={{
                      color: colors.donutTextBlue,
                    }}
                  >
                    The Donut
                  </span>{' '}
                  next door to our clinic.
                </h3>
              </div>
              <div
                id="tdp-footer-div-theDonutContent"
                data-testid="tdp-footer-div-theDonutContent"
                css={[styles.tdpFooterDivContent]}
              >
                <div
                  id="tdp-footer-div-theDonutContent-location"
                  data-testid="tdp-footer-div-theDonutContent-location"
                  css={[styles.tdpFooterDivContentLocation]}
                >
                  {theDonutContent.HEADER_FOOTER_LOCATION}
                  <div
                    id="tdp-footer-div-theDonutContent-location-address"
                    data-testid="tdp-footer-div-theDonutContent-location-address"
                    css={[styles.tdpFooterDivContentLocationAddress]}
                  >
                    <HtmlSanitize
                      contentString={theDonutContent.FOOTER_ADDRESS}
                    ></HtmlSanitize>
                  </div>
                </div>
                {/* <div
                  id="tdp-footer-div-theDonutContent-hours"
                  data-testid="tdp-footer-div-theDonutContent-hours"
                  css={[styles.tdpFooterDivContentHours]}
                >
                  <HtmlSanitize
                    contentString={theDonutContent.HEADER_FOOTER_HOURS}
                  ></HtmlSanitize>

                  <div
                    id="tdp-footer-div-theDonutContent-hours-times"
                    data-testid="tdp-footer-div-theDonutContent-hours-times"
                    css={[styles.tdpFooterDivContentHoursTimes]}
                  >
                    <HtmlSanitize
                      contentString={theDonutContent.FOOTER_HOURS_TIMES}
                    ></HtmlSanitize>
                  </div>
                </div> */}
                <div
                  id="tdp-footer-div-theDonutContent-contact"
                  data-testid="tdp-footer-div-theDonutContent-contact"
                  css={[styles.tdpFooterDivContentHours]}
                >
                  CONTACT
                  <div
                    id="tdp-footer-div-theDonutContent-contact-details"
                    data-testid="tdp-footer-div-theDonutContent-contact-details"
                    css={styles.tdpFooterDivContentContact}
                  >
                    <div id="the-donut-page-phone-number">
                      <a
                        href="tel:5075127100"
                        css={styles.tdpFooterDivContentContactNumber}
                      >
                        (507) 512-7100
                      </a>
                      <br />
                      <a
                        href="mailto:Info@DonutsAboutSmiles.com"
                        css={styles.tdpFooterDivContentContactEmail}
                      >
                        Info@DonutsAboutSmiles.com
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  id="tdp-footer-div-theDonutContent-get-involved"
                  data-testid="tdp-footer-div-theDonutContent-get-involved"
                  css={[styles.tdpFooterDivContentGetInvolved]}
                >
                  {theDonutContent.HEADER_FOOTER_GET_INVOLVED}
                  <div
                    id="tdp-footer-div-theDonutContent-get-involved-button-div"
                    data-testid="tdp-footer-div-theDonutContent-get-involved-button-div"
                    css={[styles.tdpFooterDivContentGetInvolvedButtonDiv]}
                  >
                    <a
                      id="tdp-footer-div-theDonutContent-get-involved-button"
                      data-testid="tdp-footer-div-theDonutContent-get-involved-button"
                      css={[styles.tdpFooterDivContentGetInvolvedButton]}
                      href="https://www.rochesterorthodontics.com/contact-us/adopt-a-shark/"
                      target="_blank"
                    >
                      {theDonutContent.FOOTER_BUTTON_LEARN_MORE}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default TheDonut
