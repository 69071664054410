const TheDonutContent = {
  HEADER_TITLE_1: `DONUTS, BRACES, AND...SHARKS?`,
  HEADER_TITLE_2: `HERE'S OUR STORY`,
  HEADER_P_1: `Yes, you read that correctly...we do donuts too! <br />
  <br /><a
    href="https://www.rochesterorthodontics.com/"
    target="_blank"
    css={{
      color: colors.donutTextLink,
    }}
  >Collins Orthodontics</a> is the proud home of "The Donut" which is our very own
  gourmet bakery that serves fresh-to-order mini donuts with
  17 different flavors. But how are these mini donuts
  different from your standard bucket of donuts at the fair?
  The secret is in our unique sugar blends, all created by
  MasterChef finalist Shari Mukherjee, that make every bite
  so wildly satisfying. And the best part of all - 100% of
  proceeds from every donut or beverage purchased goes
  straight to our <a
    href="https://www.rochesterorthodontics.com/contact-us/adopt-a-shark/"
    target="_blank"
  >Adopt-A-Shark</a> Program that <a
    href="https://www.rochesterorthodontics.com/contact-us/adopt-a-shark/"
    target="_blank"
  > provides free braces to children cancer survivors</a>. So be sure to scout out our <a
    href="#the-donut-page-our-menu-section"
  >menu</a> and enjoy some donuts for a great cause!`,
  HEADER_P_2: `Whether you want to grab a bag of your favorite flavor,
  or bring a box of 50 to share with your work fam, we hope
  you will enjoy our "shark bites" and help support our cause.`,
  HEADER_OUR_MENU: `OUR MENU`,
  OUR_MENU_CONTENT: `We partnered with MasterChef ﬁnalist, Shari Mukherjee, on our
  proprietary sugar blends. Whether you&#x2019;re craving something
  wildly fresh and tangy or your tried-and-trusted favorites,
  our shark bites have your taste buds covered. Wash them down
  with one of our refreshing fruity beverages or a hot cup of
  coffee.`,
  HEADER_FLAVORS: `FLAVORS`,
  HEADER_BEVERAGES: `BEVERAGES`,
  BEVERAGES_CONTENT: `Hot Chocolate, Hot Coffee, Cold Brew, Lemonade, Fruit
  Smoothie, Can of Pop, Bottle of Water`,
  HEADER_OUR_CAUSE: `OUR CAUSE`,
  OUR_CAUSE_1: `At The Donut, we&#x2019;re on a mission to serve by creating a happy, sweet, and refreshing space for 
    our guests that supports our Adopt-A-Shark Program—giving free braces to pediatric cancer 
    survivors.  Our vision for The Donut is to give our patients and their families a one-of-a-kind 
    experience at our office with a donut shop next door serving delicious mini donuts with a 
    unique mix of sugars and a fresh assortment of fruity beverages and coffee.`,
  OUR_CAUSE_2: `Collins Orthodontics started the Adopt-A-Shark Program in 2018 to offer free orthodontic care to 
    pediatric cancer survivors. Giving back to the community is a priority at our clinic, and we identified the significant need among children cancer survivors and their families. The recovery journey 
    puts a huge financial strain on them—making orthodontic treatment an economical challenge. The 
    Adopt-A-Shark Program makes orthodontic treatment possible, providing beautiful smiles and  
    confidence to children who have persevered in the face of adversity and overwhelming obstacles. <a
    href="https://www.rochesterorthodontics.com/contact-us/adopt-a-shark/"
    target="_blank"
  >Learn more</a> about the Adopt-A-Shark Program.`,
  DONATE_BUTTON_TEXT: `DONATE`,
  HEADER_BEHIND_OUR_WINGS: `THE STORY BEHIND OUR WINGS`,
  BEHIND_OUR_WINGS_CONTENT: `The wings painted at our clinic right outside of The Donut—by nationally renowned Nashville Wings artist, Kelsey
  Montague—are inspired by child cancer survivors. They
  symbolize the inspiring hope and courage these little heroes
  have and our shared goal of lifting them up—together. We
  encourage you to share your photos and snap selfies with the
  hashtag, <span css={colors.donutTextBlue}>#whatliftsyou, </span>
  to get more eyes on our cause.`,
  HEADER_FOOTER_LOCATION: `LOCATION`,
  FOOTER_ADDRESS: `2946 Jeremiah Ln NW <br />
  <div>Rochester, MN 55901</div>`,
  HEADER_FOOTER_HOURS: `HOURS`,
  FOOTER_HOURS_TIMES: `Mon - Fri: 7am - 5:30pm <br />
  <div>Sat &amp; Sun: Closed</div>`,
  HEADER_FOOTER_GET_INVOLVED: `GET INVOLVED`,
  FOOTER_BUTTON_LEARN_MORE: `LEARN MORE`,
  FLAVOR_LIST: [
    {
      flavorCategory: 'TRADITIONAL',
      flavorCatId: 'c396520e-b169-41f8-bc56-241f2be5db68',
      flavors: [
        {
          flavorId: '4be0c109-c698-4bd6-9113-cb43cd1f3762',
          flavorName: 'Old Fashioned',
          flavorPreText: '',
          flavorText:
            'Our classic old-fashioned shark bites dusted in white sugar.',
        },
        {
          flavorId: '6c5f17ab-1eaf-49e1-8984-8d84476d20c3',
          flavorName: 'Cinnamon Sugar',
          flavorPreText: '',
          flavorText: 'Made for the sweetest tooth with cinnamon and sugar.',
        },
        {
          flavorId: 'f430d6c4-3a63-449e-bab7-be189fffd5cf',
          flavorName: 'Apple Cinnamon',
          flavorPreText: '',
          flavorText: 'Shark bites dusted in tantalizing apple and cinnamon.',
        },
        {
          flavorId: '52563c71-b5e8-4df8-92d3-7ee4150b40e6',
          flavorName: 'Maple',
          flavorPreText: '',
          flavorText: 'A syrupy-sweet batch of shark bites.',
        },
      ],
    },
    {
      flavorCategory: 'NOSTALGIC',
      flavorCatId: '31073197-87ac-4c40-ba86-65f108bf58d6',
      flavors: [
        {
          flavorId: 'ba4508e0-b4c4-40cd-8d4b-8fece670c4ba',
          flavorName: 'Rocky Road',
          flavorPreText: '',
          flavorText:
            'Somewhere between a rock and a sweet place, ride the wave with our rocky road shark bites.',
        },
        {
          flavorId: 'c6ad2651-461f-4a62-9794-5b0239d4a5fa',
          flavorName: 'Skittles',
          flavorPreText: '',
          flavorText:
            'Taste the rainbow with these fruity, Skittles flavored shark bites.',
        },
        {
          flavorId: '0735008b-9a90-4ca9-a269-0a5b621ac5bb',
          flavorName: 'Frosted Animal Cookies',
          flavorPreText: '',
          flavorText:
            'These animal-cookie ﬂavored shark bites are so sweet, it’s wild!',
        },
        {
          flavorId: '717d442a-2534-4ac0-a8db-934ca7851e0a',
          flavorName: 'Honey Nut Cheerios',
          flavorPreText: '',
          flavorText:
            'A delicious spin on a breakfast classic, Honey Nut Cheerios.',
        },
      ],
    },
    {
      flavorCategory: 'FRUITY',
      flavorCatId: '0c332358-c5e3-4e33-bc42-76c2d7eec487',
      flavors: [
        {
          flavorId: 'a648f83a-16a6-4a6d-964f-7d516653df78',
          flavorName: 'Key Lime Pie',
          flavorPreText: '',
          flavorText: 'Key lime pie caught in donut form. Jawesome!',
        },
        {
          flavorId: 'c294cbef-8dd8-45e3-a61b-a4723a9b2267',
          flavorName: 'Orange Tang',
          flavorPreText: '',
          flavorText:
            'Get ready for our orange-Tang shark bites, packed with ﬂavor.',
        },
        {
          flavorId: '7a1ecb9a-536b-41e5-86cd-ab115fbdbcd0',
          flavorName: 'Lemonade',
          flavorPreText: '',
          flavorText: 'As refreshing as fresh-squeezed lemonade.',
        },
        {
          flavorId: '416eef29-a381-4a50-8cf4-ddf1fb72e484',
          flavorName: 'Fruit Loops',
          flavorPreText: '',
          flavorText: 'Just like Fruit Loops, but better!',
        },
      ],
    },
    {
      flavorCategory: 'HOUSE FAVORITES',
      flavorCatId: 'fcc57c7a-5be4-43bb-9a32-4c453141a238',
      flavors: [
        {
          flavorId: '4bac398f-0f08-4e55-b21b-3561637338ce',
          flavorName: 'Peanut Butter Pretzel',
          flavorPreText: '',
          flavorText: 'Delicious, peanut butter pretzel shark bites.',
        },
        {
          flavorId: 'fd3b3dd6-2250-415c-bdfa-3066ab8335be',
          flavorName: 'Cookies n’ Cream',
          flavorPreText: '',
          flavorText: 'Cookies-and-cream shark bites, ready to chew.',
        },
        {
          flavorId: '4a61e161-9710-4f9f-832a-72fc5a108960',
          flavorName: 'Oatmeal Cookie',
          flavorPreText: '',
          flavorText: 'Savory-and-sweet oatmeal cookie shark bites.',
        },
        {
          flavorId: '9f55834b-ccf6-4ec8-a9fc-bfdb2fd9b2f4',
          flavorName: 'Chinese 5 Spice',
          flavorPreText: '',
          flavorText: 'Shark bites dunked in ﬁve Chinese spices.',
        },
      ],
    },
  ],
}

export default TheDonutContent
