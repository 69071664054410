// App utils
import { theme } from '../utils/typography'
import { colors } from '../utils/theme'

const styles = {
  flavorMainDiv: {
    minWidth: '25%',
    minHeight: '75%',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  flavorCategoryH3: {
    color: colors.donutTextLight,
    fontFamily: theme.donutHeaderFontFamily,
    fontSize: '30pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  flavorCategoryList: {
    listStyle: 'none',
    padding: '0',
    marginTop: '.5rem',
  },
  flavorCategoryListItem: {
    marginBottom: '15px',
  },
  flavorCategoryListItemH4: {
    color: colors.donutTextBlue,
    fontFamily: theme.donutHeaderFontFamily,
    fontSize: '24pt',
    lineHeight: '1.2',
    fontWeight: 'normal',
    fontStyle: 'normal',
    marginBottom: '0',
  },
  flavorCategoryListItemPretext: {
    color: colors.donutTextLight,
    fontFamily: theme.donutFontFamily,
    fontSize: '1.3rem',
    lineHeight: '1.5',
    letterSpacing: '.9px',
  },
  flavorCategoryListItemText: {
    color: colors.donutTextLight,
    fontFamily: theme.donutFontFamily,
    fontSize: '1.3rem',
    lineHeight: '1.5',
    letterSpacing: '.9px',
  },
}

export default styles;